import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { ArticleModel } from '../models/article.model'

class ArticlesApiService<M extends ArticleModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export function useArticlesApiService() {
    return new ArticlesApiService({
        endpoint: '/articles',
    }, ArticleModel)
}
